import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateGroupAPI } from "../api";

export const updateGroup = createAsyncThunk(
  "updateGroup/fetch",
  async (groupData, thunkAPI) => {
    console.log('...starting update');
    try {
      const { id, data } = groupData;
      console.log('check: ', data);
      const formData = new FormData();
      Object.entries(data).forEach((el) => {
        formData.append(el[0], el[1]);
      });
      console.log(formData);
      const res = await updateGroupAPI(id, formData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  group: null,
  id: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "updateGroup",
  initialState,
  reducers: {
    setUpdateGroupId: (state, { payload }) => {
      state.id = payload;
      return state;
    },
    resetUpdated: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [updateGroup.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateGroup.fulfilled]: (state, { payload }) => {
      state.group = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateGroup.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { setUpdateGroupId, resetUpdated } = slice.actions;

// Selectors
export const updateGroupLoadingSelector = (state) => state.updateGroup.loading;
export const updateGroupHasErrorsSelector = (state) => state.updateGroup.hasErrors;
export const updateGroupErrorMessageSelector = (state) => state.updateGroup.error;
export const updateGroupSelector = (state) => state.updateGroup.group;
export const updateGroupIdSelector = (state) => state.updateGroup.id;
