import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getStudentAPI } from "../api";

export const getStudent = createAsyncThunk(
  "updateStudent/fetch",
  async (studentId, thunkAPI) => {
    console.log('...starting update');
    try {
      const res = await getStudentAPI(studentId);
      console.log(res);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  student: null,
  id: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "getStudent",
  initialState,
  reducers: {
    setUpdateStudentId: (state, { payload }) => {
      state.id = payload;
      return state;
    },
    resetUpdated: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [getStudent.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getStudent.fulfilled]: (state, { payload }) => {
      state.student = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getStudent.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { setUpdateStudentId, resetUpdated } = slice.actions;

// Selectors
export const getStudentLoadingSelector = (state) => state.getStudent.loading;
export const getStudentHasErrorsSelector = (state) => state.getStudent.hasErrors;
export const getStudentErrorMessageSelector = (state) => state.getStudent.error;
export const getStudentSelector = (state) => state.getStudent.student;
export const getStudentIdSelector = (state) => state.getStudent.id;
