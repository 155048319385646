import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Row, Col, Button    } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
  } from '@ant-design/icons';


import { collapsedSelector, handleCollapse } from '../slices/sidebarSlice';
import { signOut } from '../../auth/slices/authSlice';

const { Header } = Layout;

export const TopHeader = () => {

    const collapsed = useSelector(collapsedSelector);
    const dispatch = useDispatch();

    const logOut = async () => {
        await dispatch(signOut());
    }

    return (
        <Header className="site-layout-background" style={{ padding: '0' }}>
            <Row justify='space-between' align='middle'>
                <Col span={2}>
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger',
                        onClick: () => dispatch(handleCollapse()),
                        style: {color: '#ffffff', padding: '0 0 0 20px'},
                    })}
                </Col>
                <Col span={4} align='center'>
                    <Button ghost onClick={logOut}>Выйти</Button>
                </Col>
            </Row>
        </Header>
    )
}
