import styled from 'styled-components';

export const SidebarLogoWrapper = styled.div`
    width: 100%;
    padding: ${({collapsed}) => collapsed ? '0' : '8px 24px' };
    transition: all 0.2s ease-in-out;

    img {
        padding: 18px 14px;
    }
`;