import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createParentAPI } from "../api";

export const createParent = createAsyncThunk(
  "studentCreateParent/fetch",
  async (parentFormData, thunkAPI) => {
    try {
      const formData = new FormData();
      Object.entries(parentFormData).forEach((data) => {
        formData.append(data[0], data[1]);
      });
      console.log('added :', formData);
      const res = await createParentAPI(formData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  parent: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "studentCreateParent",
  initialState,
  reducers: {
    resetCreatedParent: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [createParent.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [createParent.fulfilled]: (state, { payload }) => {
      state.parent = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [createParent.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetCreatedParent } = slice.actions;

// Selectors
export const studentCreateParentLoadingSelector = (state) => state.studentCreateParent.loading;
export const studentCreateParentHasErrorsSelector = (state) => state.studentCreateParent.hasErrors;
export const studentCreateParentErrorMessageSelector = (state) => state.studentCreateParent.error;
export const studentCreateParentSelector = (state) => state.studentCreateParent.parent;
