import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { studentAnthropometryAPI } from "../api";

export const getStudentAnthropometry = createAsyncThunk(
  "studentsAnthropometry/fetch",
  async (studentId, thunkAPI) => {
    console.log('...получаем антропометрию');
    try {
      const res = await studentAnthropometryAPI(studentId);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  anthropometry: null,
  selectedId: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "studentsAnthropometry",
  initialState,
  reducers: {
    resetUpdated: (state) => {
      state = initialState;
      return state;
    },
    setSelectedId: (state, { payload }) => {
      state.selectedId = payload;
      return state;
    },
    resetSelectedId: (state) => {
      state.selectedId = null;
      return state;
    },
  },
  extraReducers: {
    [getStudentAnthropometry.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getStudentAnthropometry.fulfilled]: (state, { payload }) => {
      state.anthropometry = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getStudentAnthropometry.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetUpdated, setSelectedId, resetSelectedId } = slice.actions;

// Selectors
export const studentsAnthropometryLoadingSelector = (state) => state.studentsAnthropometry.loading;
export const studentsAnthropometryHasErrorsSelector = (state) => state.studentsAnthropometry.hasErrors;
export const studentsAnthropometryErrorMessageSelector = (state) => state.studentsAnthropometry.error;
export const studentsAnthropometrySelector = (state) => state.studentsAnthropometry.anthropometry;
export const studentsAnthropometrySelectedIdSelector = (state) => state.studentsAnthropometry.selectedId;
// export const getStudentIdSelector = (state) => state.getStudent.id;
