import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addTestAPI } from "../api";

export const addTest = createAsyncThunk(
  "addTest/fetch",
  async (testData, thunkAPI) => {
    try {
      const formData = new FormData();
      Object.entries(testData).forEach((data) => {
        formData.append(data[0], data[1]);
      });
      console.log('formData in slice^', formData);
      const res = await addTestAPI(formData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
//   dayId: null,
  position: null,
  res: null,
  loading: false, 
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "addTest",
  initialState,
  reducers: {
    resetAddedTest: (state) => {
      state = initialState;
      return state;
    },
    // setDayId: (state, { payload }) => {
    //     state.dayId = payload;
    //     return state;
    // },
    setPosition: (state, { payload }) => {
        state.position = payload;
        return state;
    }
  },
  extraReducers: {
    [addTest.pending]: (state) => {
        state.loading = true;
        state.hasErrors = false;
        state.error = "";
        return state;
    },
    [addTest.fulfilled]: (state, { payload }) => {
        state.res = payload;
        state.hasErrors = false;
        state.error = "";
        state.loading = false;
      return state;
    },
    [addTest.rejected]: (state, { error }) => {
        state.loading = false;
        state.hasErrors = true;
        state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetAddedTest, setDayId, setPosition } = slice.actions;

// Selectors
// export const addTestDayIdSelector = (state) => state.addTest.dayId;
export const addTestPositionSelector = (state) => state.addTest.position;
export const addTestLoadingSelector = (state) => state.addTest.loading;
// export const collapsedSelector = (state) => state.allSidebars.collapsed;
