import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { anthropometryAgesAPI } from "../api";

export const getAnthropometryAges = createAsyncThunk(
  "anthropometryAges/fetch",
  async (_, thunkAPI) => {
    console.log('...starting update');
    try {
      const res = await anthropometryAgesAPI();
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  anthropometryAges: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "anthropometryAges",
  initialState,
  reducers: {
    resetAges: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [getAnthropometryAges.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAnthropometryAges.fulfilled]: (state, { payload }) => {
      state.anthropometryAges = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAnthropometryAges.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetAges } = slice.actions;

// Selectors
export const anthropometryAgesLoadingSelector = (state) => state.anthropometryAges.loading;
export const anthropometryAgesHasErrorsSelector = (state) => state.anthropometryAges.hasErrors;
export const anthropometryAgesErrorMessageSelector = (state) => state.anthropometryAges.error;
export const anthropometryAgesSelector = (state) => state.anthropometryAges.anthropometryAges;
// export const getStudentIdSelector = (state) => state.getStudent.id;
