import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allParentsAPI } from "../api";

export const getAllParents = createAsyncThunk(
  "studentsAllParents/fetch",
  async (studentId, thunkAPI) => {
    try {
      const res = await allParentsAPI(studentId);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  parents: null,
  pagination: null,
  loading: false, 
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "studentsAllParents",
  initialState,
  reducers: {
    resetParents: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [getAllParents.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAllParents.fulfilled]: (state, { payload }) => {
      state.parents = payload.data;
      // state.pagination = {
      //   current: payload.meta.current_page,
      //   total: payload.meta.total,
      //   pageSize: payload.meta.per_page
      // };
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAllParents.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetParents } = slice.actions;

// Selectors
export const studentsAllParentsSelector = (state) => state.studentsAllParents.parents;
export const studentsAllParentsLoadingSelector = (state) => state.studentsAllParents.loading;
export const studentsAllParentsPaginationSelector = (state) => state.studentsAllParents.pagination;
