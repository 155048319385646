import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allUsersAPI } from "../api";

export const getAllUsersForGroup = createAsyncThunk(
  "allUsersForGroup/fetch",
  async (_, thunkAPI) => {
    try {
      const res = await allUsersAPI();
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  users: null,
  pagination: null,
  loading: false, 
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "allUsersForGroup",
  initialState,
  reducers: {
    resetUsers: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [getAllUsersForGroup.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAllUsersForGroup.fulfilled]: (state, { payload }) => {
      state.users = payload.data;
      // state.pagination = {
      //   current: payload.meta.current_page,
      //   total: payload.meta.total,
      //   pageSize: payload.meta.per_page
      // };
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAllUsersForGroup.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetUsers } = slice.actions;

// Selectors
export const allUsersForGroupSelector = (state) => state.allUsersForGroup.users;
export const allUsersForGroupLoadingSelector = (state) => state.allUsersForGroup.loading;
export const allUsersForGroupPaginationSelector = (state) => state.allUsersForGroup.pagination;
