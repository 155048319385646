import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createDishAPI } from "../api";

export const createDish = createAsyncThunk(
  "createDish/fetch",
  async (dishFormData, thunkAPI) => {
    try {
      const formData = new FormData();
      Object.entries(dishFormData).forEach((data) => {
        formData.append(data[0], data[1]);
      });
      console.log('added dish:', formData);
      const res = await createDishAPI(formData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  dish: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "createDish",
  initialState,
  reducers: {
    resetCreatedDish: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [createDish.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [createDish.fulfilled]: (state, { payload }) => {
      state.dish = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [createDish.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetCreatedDish } = slice.actions;

// Selectors
export const createDishLoadingSelector = (state) => state.createDish.loading;
export const createDishHasErrorsSelector = (state) => state.createDish.hasErrors;
export const createDishErrorMessageSelector = (state) => state.createDish.error;
export const createDishSelector = (state) => state.createDish.dish;
