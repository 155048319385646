import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import Cookies from "js-cookie";
import storage from 'reduxjs-toolkit-persist/lib/storage'; 

import { SignInPageWrapper, SignInFormOutter } from './atoms';
import { FormHeader } from './molecules';
import { Form } from './organisms';

export const SignIn = () => {

    useEffect(() => {
        Cookies.remove("access-token");
        storage.removeItem('persist:root');
    }, []);

    return (
        <SignInPageWrapper>
            <SignInFormOutter>
                <Row justify='center' align='middle'>
                    <Col>
                        <FormHeader />
                    </Col>
                    <Col>
                        <Form />
                    </Col>
                </Row>
            </SignInFormOutter>
        </SignInPageWrapper>
    )
}