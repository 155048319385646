import { 
    httpDelete, 
    httpGet, 
    httpPost 
} from "../../../api";

export const regionsAPI = () =>
  httpGet({
    url: "/api/admin/regions"
  });

export const citiesAPI = (params) =>
  httpGet({
    url: "/api/admin/cities",
    params
  });

export const kindergartensAPI = () =>
  httpGet({
    url: "/api/admin/kindergartens"
  }); 
  
export const rolesAPI = () =>
  httpGet({
    url: "/api/admin/roles"
  }); 

export const addUserAPI = (data) =>
  httpPost({
    url: "/api/admin/users",
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

export const allUsersAPI = (params) =>
  httpGet({
    url: "/api/admin/users",
    params
  });

export const deleteUserAPI = (id) =>
  httpDelete({
    url: `/api/admin/users/${id}`,
  });

export const updateUserAPI = (id, data) =>
  httpPost({
    url: `/api/admin/users/${id}`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
