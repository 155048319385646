import { 
    httpDelete, 
    httpGet, 
    httpPost 
} from "../../../api";

export const studyYearsAPI = () =>
  httpGet({
    url: "/api/admin/study_years"
  });

export const ageClassesAPI = () =>
  httpGet({
      url: "/api/admin/age_classes"
  });

export const allUsersAPI = () =>
  httpGet({
      url: "/api/admin/users"
  });

  // Groups API

export const createGroupAPI = (data) =>
  httpPost({
      url: "/api/admin/groups",
      data,
      headers: {
          'Content-Type': 'multipart/form-data'
      }
  });

export const deleteGroupAPI = (id) =>
  httpDelete({
    url: `/api/admin/groups/${id}`,
  });

export const allGroupsAPI = () =>
  httpGet({
      url: "/api/admin/groups"
  });

export const updateGroupAPI = (id, data) =>
  httpPost({
    url: `/api/admin/groups/${id}`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  // Students API

export const createStudentAPI = (data) =>
  httpPost({
      url: "/api/admin/students",
      data,
      headers: {
          'Content-Type': 'multipart/form-data'
      }
  });

export const allStudentsAPI = (params) =>
  httpGet({
    url: "/api/admin/students",
    params
  });

export const getStudentAPI = (id, params) =>
  httpGet({
    url: `/api/admin/students/${id}`,
    params
  });

export const deleteStudentAPI = (id) =>
  httpDelete({
    url: `/api/admin/students/${id}`,
  });

export const updateStudentAPI = (id, data) =>
  httpPost({
    url: `/api/admin/students/${id}`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  // Parent API

export const createParentAPI = (data) =>
  httpPost({
    url: "/api/admin/parent_of_students",
    data,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
  });

export const allParentsAPI = (params) =>
  httpGet({
    url: "/api/admin/parent_of_students",
    params
  });

export const updateParentAPI = (id, data) =>
  httpPost({
    url: `/api/admin/parent_of_students/${id}`,
    data,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
  });

  // Student Student Anthropometry

export const studentAnthropometryAPI = (params) => 
  httpGet({
    url: "/api/admin/anthropometries",
    params
  });

export const anthropometryAgesAPI = () => 
  httpGet({
    url: "/api/admin/ages"
  });

export const createStudentAnthropometryAPI = (data) =>
  httpPost({
    url: "/api/admin/anthropometries",
    data,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
  });


export const allGroupScheduleAPI = (params) =>
  httpGet({
    url: "/api/admin/timetables",
    params
  });

