import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allGroupScheduleAPI } from "../api";

export const getAllGroupSchedule = createAsyncThunk(
  "allGroupSchedule/fetch",
  async (groupId, thunkAPI) => {
    try {
        console.log('GET SCHEDULE....');
        const res = await allGroupScheduleAPI(groupId);
        return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  schedule: null,
  loading: false, 
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "allGroupSchedule",
  initialState,
  reducers: {
    resetSchedule: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [getAllGroupSchedule.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAllGroupSchedule.fulfilled]: (state, { payload }) => {
      state.schedule = payload.data;
      // state.pagination = {
      //   current: payload.meta.current_page,
      //   total: payload.meta.total,
      //   pageSize: payload.meta.per_page
      // };
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAllGroupSchedule.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetSchedule } = slice.actions;

// Selectors
export const allGroupScheduleSelector = (state) => state.allGroupSchedule.schedule;
export const allGroupScheduleLoadingSelector = (state) => state.allGroupSchedule.loading;
// export const allParentsPaginationSelector = (state) => state.allGroupSchedule.pagination;
