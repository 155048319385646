import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allUsersAPI } from "../api";

export const getAllUsers = createAsyncThunk(
  "allUsers/fetch",
  async (usersParams, thunkAPI) => {
    try {
      const res = await allUsersAPI(usersParams);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  users: null,
  pagination: null,
  loading: false, 
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "allUsers",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllUsers.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAllUsers.fulfilled]: (state, { payload }) => {
      state.users = payload.data;
      state.pagination = {
        current: payload.meta.current_page,
        total: payload.meta.total,
        pageSize: payload.meta.per_page
      };
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAllUsers.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
// export const { handleCollapse } = slice.actions;

// Selectors
export const allUsersSelector = (state) => state.allUsers.users;
export const allUsersLoadingSelector = (state) => state.allUsers.loading;
export const allUsersPaginationSelector = (state) => state.allUsers.pagination;
