import React from 'react';
import { useSelector } from 'react-redux';
import {Img} from 'react-image';
import { Link } from 'react-router-dom';
import {
    LoadingOutlined,
  } from '@ant-design/icons';

import { SidebarLogoWrapper } from '../atoms';
import LogoImg from '../../../../assets/img/sidebar-logo-white.png';
import { Title } from './sidebar-title';
import { collapsedSelector } from  '../slices/sidebarSlice';

export const SidebarLogo = () => {

    const collapsed = useSelector(collapsedSelector);

    return (
        <SidebarLogoWrapper collapsed={collapsed}>
            <Link to='/'>
                <Img src={LogoImg} loader={<LoadingOutlined />} />
                <Title />
            </Link>
        </SidebarLogoWrapper>
    )
}