import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createStudentAPI } from "../api";

export const createStudent = createAsyncThunk(
  "createStudent/fetch",
  async (studentFormData, thunkAPI) => {
    try {
      const formData = new FormData();
      Object.entries(studentFormData).forEach((data) => {
        formData.append(data[0], data[1]);
      });
      console.log('added :', formData);
      const res = await createStudentAPI(formData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  student: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "studentCreateStudent",
  initialState,
  reducers: {
    resetCreatedStudent: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [createStudent.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [createStudent.fulfilled]: (state, { payload }) => {
      state.student = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [createStudent.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetCreatedStudent } = slice.actions;

// Selectors
export const studentCreateStudentLoadingSelector = (state) => state.studentCreateStudent.loading;
export const studentCreateStudentHasErrorsSelector = (state) => state.studentCreateStudent.hasErrors;
export const studentCreateStudentErrorMessageSelector = (state) => state.studentCreateStudent.error;
export const studentCreateStudentSelector = (state) => state.studentCreateStudent.student;
