import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { 
  persistReducer, 
  FLUSH, 
  REHYDRATE, 
  PAUSE, 
  PERSIST, 
  PURGE, 
  REGISTER 
} from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1';

import reducers from "./rootReducer";

console.log(storage);
const persistConfig = {
  key: 'admin',
  storage: storage,
  stateReconciler: autoMergeLevel1,
};

const _persistedReducer = persistReducer(persistConfig, reducers);


const store = configureStore({
  reducer: _persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      /* ignore persistance actions */
      ignoredActions: [
        FLUSH,
        REHYDRATE,
        PAUSE,
        PERSIST,
        PURGE,
        REGISTER
      ],
    },
  }),
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
