import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateDishAPI } from "../api";

export const updateDish = createAsyncThunk(
  "updateDish/fetch",
  async (dishData, thunkAPI) => {
    try {
      const { id, data } = dishData;
      const formData = new FormData();
      Object.entries(data).forEach((el) => {
        formData.append(el[0], el[1]);
      });
      console.log('added dish:', formData);
      const res = await updateDishAPI(id, formData);
      console.log(res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  dish: null,
  id: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "updateDish",
  initialState,
  reducers: {
    resetUpdatedDish: (state) => {
      state = initialState;
      return state;
    },
    setUpdateDishId: (state, { payload }) => {
      state.id = payload;
      return state;
    }
  },
  extraReducers: {
    [updateDish.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateDish.fulfilled]: (state, { payload }) => {
      state.dish = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateDish.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetUpdatedDish, setUpdateDishId } = slice.actions;

// Selectors
export const updateDishLoadingSelector = (state) => state.updateDish.loading;
export const updateDishHasErrorsSelector = (state) => state.updateDish.hasErrors;
export const updateDishErrorMessageSelector = (state) => state.updateDish.error;
export const updateDishSelector = (state) => state.updateDish.dish;
export const updateDishIdSelector = (state) => state.updateDish.id;
