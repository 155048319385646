import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { allDishesAPI } from "../api";

export const getAllDishes = createAsyncThunk(
  "allDishes/fetch",
  async (_, thunkAPI) => {
    try {
      const res = await allDishesAPI();
      console.log(res);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  dishes: null,
  pagination: null,
  loading: false, 
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "allDishes",
  initialState,
  reducers: {
    resetDishes: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [getAllDishes.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAllDishes.fulfilled]: (state, { payload }) => {
      state.dishes = payload.data;
      // state.pagination = {
      //   current: payload.meta.current_page,
      //   total: payload.meta.total,
      //   pageSize: payload.meta.per_page
      // };
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [getAllDishes.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetDishes } = slice.actions;

// Selectors
export const allDishesSelector = (state) => state.allDishes.dishes;
export const allDishesLoadingSelector = (state) => state.allDishes.loading;
export const allDishesPaginationSelector = (state) => state.allDishes.pagination;
