import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateParentAPI } from "../api";

export const updateParent = createAsyncThunk(
  "updateParent/fetch",
  async (studentData, thunkAPI) => {
    console.log('...starting update');
    try {
      const { id, data } = studentData;
      console.log('check: ', data);
      const formData = new FormData();
      Object.entries(data).forEach((el) => {
        formData.append(el[0], el[1]);
      });
      const res = await updateParentAPI(id, formData);
      console.log('updateParentAPI', res);
      return res.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  parent: null,
  id: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "updateParent",
  initialState,
  reducers: {
    setUpdateParentId: (state, { payload }) => {
      state.id = payload;
      return state;
    },
    resetParentId: (state, { payload }) => {
      state.id = null;
      return state;
    },
    resetUpdated: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [updateParent.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateParent.fulfilled]: (state, { payload }) => {
      state.parent = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [updateParent.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { setUpdateParentId, resetUpdated, resetParentId } = slice.actions;

// Selectors
export const updateParentLoadingSelector = (state) => state.updateParent.loading;
export const updateParentHasErrorsSelector = (state) => state.updateParent.hasErrors;
export const updateParentErrorMessageSelector = (state) => state.updateParent.error;
export const updateParentSelector = (state) => state.updateParent.parent;
export const updateParentIdSelector = (state) => state.updateParent.id;
