import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createStudentAnthropometryAPI } from "../api";

export const addStudentAnthropometry = createAsyncThunk(
  "addStudentAnthropometry/fetch",
  async (studentData, thunkAPI) => {
    console.log('...adding anthropometry');
    try {
        const formData = new FormData();
        Object.entries(studentData).forEach((data) => {
            formData.append(data[0], data[1]);
        });
        const res = await createStudentAnthropometryAPI(formData);
        console.log(res);
        return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Slice
const initialState = {
  res: null,
  loading: false,
  hasErrors: false,
  error: "",
};

const slice = createSlice({
  name: "addStudentAnthropometry",
  initialState,
  reducers: {
    resetRes: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: {
    [addStudentAnthropometry.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [addStudentAnthropometry.fulfilled]: (state, { payload }) => {
      state.res = payload;
      state.loading = false;
      state.hasErrors = false;
      state.error = "";
      return state;
    },
    [addStudentAnthropometry.rejected]: (state, { error }) => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error.message;
      return state;
    },
  },
});

export default slice.reducer;

// Actions
export const { resetRes } = slice.actions;

// Selectors
export const addStudentAnthropometryLoadingSelector = (state) => state.addStudentAnthropometry.loading;
export const addStudentAnthropometryHasErrorsSelector = (state) => state.addStudentAnthropometry.hasErrors;
export const addStudentAnthropometryErrorMessageSelector = (state) => state.addStudentAnthropometry.error;
export const addStudentAnthropometrySelector = (state) => state.addStudentAnthropometry.res;
// export const getStudentIdSelector = (state) => state.getStudent.id;
